var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "page-container pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "manutencao"
  }, [_c('h1', [_vm._v("Site em Manutenção!")]), _c('h2', [_vm._v(" Desculpe pelo inconveniente, mas estamos realizando uma atualização no momento. Estaremos novamente online em alguns minutos. ")]), _c('v-img', {
    staticClass: "manutencao-img",
    attrs: {
      "src": "/img/manutencao.png"
    }
  })], 1), _c('lp-social'), _c('lp-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }