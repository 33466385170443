var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "white--text pt-8 pb-16"
  }, [_c('v-container', {
    staticClass: "pb-16"
  }, [_c('v-row', [_c('v-col', [_c('h4', [_vm._v("Governo")]), _c('hr', {
    staticClass: "mt-1 mb-5 info"
  }), _c('ul', [_c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/governador"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Governador")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/mapa-do-trabalho"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Mapa do Trabalho")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/prefeituras"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Prefeituras")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/galeria-de-governadores"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Galeria de Governadores")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/telefones-uteis"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Telefones úteis")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/licitacoes"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Licitações")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/fale-conosco/21-contato"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Contato")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "http://www.educacao.pe.gov.br/portal/?men=142"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Ouvidoria")])])])]), _c('v-col', [_c('h4', [_vm._v("Pernambuco")]), _c('hr', {
    staticClass: "mt-1 mb-5 green"
  }), _c('ul', [_c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/historia"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("História")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/geografia"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Geografia")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/populacao"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("População")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/turismo"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Turismo")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/cultura"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Cultura")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/simbolos"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Símbolos")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/constituicao"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Constituição")])])])]), _c('v-col', [_c('h4', [_vm._v("Imprensa")]), _c('hr', {
    staticClass: "mt-1 mb-5 yellow"
  }), _c('ul', [_c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/blog"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Blog de Notícias")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/agenda-do-governador"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Agenda do Governador")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/radio-sei"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Rádio SEI")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/banco-de-pautas"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Banco de Pautas")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/downloads"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Downloads")])])])]), _c('v-col', [_c('h4', [_vm._v("Secretarias")]), _c('hr', {
    staticClass: "mt-1 mb-5 red"
  }), _c('ul', [_c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/secretarias"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Secretarias")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/orgaos"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Órgãos")])]), _c('li', [_c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.pe.gov.br/todos-os-servicos"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("Serviços")])])])])], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "footer-logo mt-16",
    attrs: {
      "alt": "Logotipo do governo do estado de Pernambuco",
      "src": "/img/brasao_256.png"
    }
  })])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }