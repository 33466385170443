<template>
  <section id="section5" class="blue lighten-3">
    <v-container>
      <v-row class="py-5">
        <v-col class="d-none d-md-flex font-weight-bold"
          >Siga-nos nas nossas redes sociais</v-col
        >
        <v-col
          class="
            d-flex
            justify-space-around justify-md-space-between
            text-center text-md-right
          "
        >
          <a
            @click="go" 
            rel="noreferrer noopener"
            class="
              font-weight-medium
              light-blue--text
              text--darken-4 text-decoration-none
            "
            target="_blank"
            href="https://www.instagram.com/educacaopeoficial/"
          >
            <v-icon>mdi-instagram</v-icon>
          </a>
          <a
            @click="go" 
            rel="noreferrer noopener"
            class="
              font-weight-medium
              light-blue--text
              text--darken-4 text-decoration-none
            "
            target="_blank"
            href="https://www.facebook.com/secretariadeeducacaodepernambuco"
          >
            <v-icon>mdi-facebook</v-icon>
          </a>
          <a
            @click="go" 
            rel="noreferrer noopener"
            class="
              font-weight-medium
              light-blue--text
              text--darken-4 text-decoration-none
            "
            target="_blank"
            href="https://www.youtube.com/user/EducacaoPE"
          >
            <v-icon>mdi-youtube</v-icon>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "LandingPageSocial",
  props: {
    preview: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    go(event) {
      if (this.preview) {
        event.preventDefault();
      }
    }
  }
}
</script>