<template>
  <v-container fluid class="page-container pa-0">
    <div class="manutencao">
      <h1>Site em Manutenção!</h1>
      <h2>
        Desculpe pelo inconveniente, mas estamos realizando uma atualização no
        momento. Estaremos novamente online em alguns minutos.
      </h2>
      <v-img class="manutencao-img" src="/img/manutencao.png"></v-img>
    </div>
    <lp-social></lp-social>
    <lp-footer></lp-footer>
  </v-container>
</template>

<script>
import LpSocial from "components/landing-page/LandingPageSocial";
import LpFooter from "components/landing-page/LandingPageFooter";

export default {
  name: "ManutencaoView",
  components: { LpSocial, LpFooter },
  mounted() { this.$scrollToTop()},
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Segoe UI"), local("Open Sans"), local("OpenSans"),
    url(https://themes.googleusercontent.com/static/fonts/opensans/v6/K88pR3goAWT7BTt32Z01mz8E0i7KZn-EPnyo3HZu7kw.woff)
      format("woff");
}

body {
  font-family: "Open Sans", sans-serif, Arial;
}

h1 {
  font-size: 4em;
}

.manutencao {
  color: #333;
  margin: 5em auto 5em;
  text-align: center;
  width: 40em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manutencao h1 {
  font-size: 4em;
  font-weight: normal;
  line-height: 120px;
  margin: 10px 0;
}

.manutencao h2 {
  color: #666666;
  font-size: 18px;
  font-weight: normal;
}

.manutencao-img {
  width: 10em;
  margin-top: 3em;
}
</style>
