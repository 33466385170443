var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "blue lighten-3",
    attrs: {
      "id": "section5"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "py-5"
  }, [_c('v-col', {
    staticClass: "d-none d-md-flex font-weight-bold"
  }, [_vm._v("Siga-nos nas nossas redes sociais")]), _c('v-col', {
    staticClass: "d-flex justify-space-around justify-md-space-between text-center text-md-right"
  }, [_c('a', {
    staticClass: "font-weight-medium light-blue--text text--darken-4 text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.instagram.com/educacaopeoficial/"
    },
    on: {
      "click": _vm.go
    }
  }, [_c('v-icon', [_vm._v("mdi-instagram")])], 1), _c('a', {
    staticClass: "font-weight-medium light-blue--text text--darken-4 text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.facebook.com/secretariadeeducacaodepernambuco"
    },
    on: {
      "click": _vm.go
    }
  }, [_c('v-icon', [_vm._v("mdi-facebook")])], 1), _c('a', {
    staticClass: "font-weight-medium light-blue--text text--darken-4 text-decoration-none",
    attrs: {
      "rel": "noreferrer noopener",
      "target": "_blank",
      "href": "https://www.youtube.com/user/EducacaoPE"
    },
    on: {
      "click": _vm.go
    }
  }, [_c('v-icon', [_vm._v("mdi-youtube")])], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }